import { useState } from 'react'
import './App.css';

function App() {

  const [reviewCounter, setReviewCounter] = useState(0);

  const reviews = 
  [
    {
      author: "Karolina Szawica",
      starCount: 5,
      desc: "Pełen profesjonalizm, miła atmosfera, polecam"
    },
    {
      author: "Agnieszka Środowska",
      starCount: 5,
      desc: "Polecam. Pełen profesjonalizm i wspaniały człowiek, który rzeczywiście potrafi pomóc."
    },
  ]

  const handleBackReview = () => {
    if (reviewCounter === 0)
    {
      setReviewCounter(2)
    } else {
      setReviewCounter(reviewCounter - 1)
    }
    
  }

  const handleNextReview = () => {
    if (reviewCounter === 2)
    {
      setReviewCounter(0)
    } else {
      setReviewCounter(reviewCounter + 1)
    }
  }

  const handleHomeScroll = () => {
    const navbarHeight = document.getElementsByClassName('navbar')[0].clientHeight;
    const element = document.getElementsByClassName('home');
    if (element) {
      window.scroll({
        top: element[0].offsetTop - navbarHeight,
        behavior: 'smooth'
      })
    }
  }
  
  const handleAboutScroll = () => {
    const navbarHeight = document.getElementsByClassName('navbar')[0].clientHeight;
    const element = document.getElementsByClassName('about-me');
    if (element) {
      window.scroll({
        top: element[0].offsetTop - navbarHeight,
        behavior: 'smooth'
      })
    }
  }
  
  const handleExpirienceScroll = () => {
    const navbarHeight = document.getElementsByClassName('navbar')[0].clientHeight;
    const element = document.getElementsByClassName('expirience');
    if (element) {
      window.scroll({
        top: element[0].offsetTop - navbarHeight,
        behavior: 'smooth'
      });
    }
  }

  const handleReviewsScroll = () => {
    const navbarHeight = document.getElementsByClassName('navbar')[0].clientHeight;
    const element = document.getElementsByClassName('reviews');
    if (element) {
      window.scroll({
        top: element[0].offsetTop - navbarHeight,
        behavior: 'smooth'
      });
    }
  }
  
  const handleContactScroll = () => {
    const navbarHeight = document.getElementsByClassName('navbar')[0].clientHeight;
    const element = document.getElementsByClassName('contact');
    if (element) {
      window.scroll({
        top: element[0].offsetTop - navbarHeight,
        behavior: 'smooth'
      })
    }
  }

  return (
    <div className="App">
      <div className='navbar'>
        <a href='/' className='logo'>
          <img src={require('./files/logo.jpg')} id='logo' alt='logo'/>
        </a>

        <div className='section-wrapper'>
          <span style={{cursor: 'pointer'}} onClick={handleHomeScroll} className='nav-button'>HOME</span>
          <span style={{cursor: 'pointer'}} onClick={handleAboutScroll} className='nav-button'>O MNIE</span>
          <span style={{cursor: 'pointer'}} onClick={handleExpirienceScroll} className='nav-button'>DOŚWIADCZENIE</span>
          <span style={{cursor: 'pointer'}} onClick={handleReviewsScroll} className='nav-button'>OPINIE</span>
          <span style={{cursor: 'pointer'}} onClick={handleContactScroll} className='nav-button'>KONTAKT</span>
        </div>

        <div className='phone'>
          <a href='tel:694295954' id='phone-holder' style={{textDecoration: "none", color: "black"}}>
            <img src={require('./files/phone.png')} alt='phone-icon' id='phone-icon'/>
            694 295 954
          </a>
        </div>
      </div>

      <div className='home'>
        <div className='home-content'>
          <div className='home-main'>Gabinet Psychoterapii Uzależnień Kielce i Okolice</div>
          <div className='home-short'>
            Bożena Stępień
          </div>
          <div className='home-address-street'>ul. Spacerowa 11</div>
          <div className='home-address-town'>Zagnańsk 26-050</div>
          <div className='home-check-more' onClick={handleAboutScroll}>Zobacz więcej</div>
          <img src={require('./files/arrow.png')} alt="arrow" id='arrow'/>
        </div>
      </div>

      <div className='about-me'>
        <img src={require('./files/portrait.jpg')} alt="portrait" id='portrait'/>
        <div className='about-me-wrapper'>
          <div className='about-me-main'>
            O mnie
          </div>
          <div className='about-me-text'>
            <a href="/#" style={{color: "#00B0E7", textDecoration: "none", fontWeight: "bold"}}>Bożena Stępień</a> - certyfikowany specjalista psychoterapii uzależnień. Od wielu lat pomagam osobom uzależnionym od alkoholu, narkotyków, leków, a także uzależnionym od czynności (hazard, seks, zakupy, internet, itp.). Pomagam także rodzinom osób uzależnionych oraz osobom, które wychowały się w rodzinie z problemem alkoholowym.
          </div>
        </div>
      </div>

      <div className='expirience'>
        <div className='expirience-title'>
          Doświadczenie
        </div>
        <div className='expirience-content'>
          <div className='expirience-wrapper'>
            <img src={require('./files/pointer.png')} alt="pointer" class='pointer'/>
            <div className='expirience-text'>Świętokrzyskie Centrum Psychiatrii w Morawicy</div>
          </div>
          <div className='expirience-wrapper'>
            <img src={require('./files/pointer.png')} alt="pointer" class='pointer'/>
            <div className='expirience-text'>Poradnia Leczenia Uzależnień ŚCP w Morawicy z siedzibą w Kielcach</div>
          </div>
          <div className='expirience-wrapper'>
            <img src={require('./files/pointer.png')} alt="pointer" class='pointer'/>
            <div className='expirience-text'>Punkt konsultacyjny przy Gminnym Ośrodku Pomocy Społecznej w Stąporkowie</div>
          </div>
          <div className='expirience-wrapper'>
            <img src={require('./files/pointer.png')} alt="pointer" class='pointer'/>
            <div className='expirience-text'>Poradnia Leczenia Uzależnień Promedic Staszów</div>
          </div>
          <div className='expirience-wrapper'>
            <img src={require('./files/pointer.png')} alt="pointer" class='pointer'/>
            <div className='expirience-text'>Poradnia Leczenia Uzależnień w Skarżysku Kamiennej</div>
          </div>
          <div className='expirience-wrapper'>
            <img src={require('./files/pointer.png')} alt="pointer" class='pointer'/>
            <div className='expirience-text'>Prywatny Ośrodek Leczenia Uzależnień w Morawicy</div>
          </div>
        </div>
      </div>

      <div className='reviews'>
        <div className='reviews-head'>Opinie</div>
        <div className='reviews-desc'>Opinie naszych klientów po wykonaniu naszych usług</div>
        <div className="reviews-slider">
          <button onClick={handleBackReview} className='arrow-btn-left'><img src={require("./files/arrowrew.png")} alt="arrow" className="arrow-img-left"/></button>
            <div className='review-tile'>
              <div className='review-tile-head'>
                <span className='tile-title'>{reviews[reviewCounter].author}</span>
                <span>{reviews[reviewCounter].starCount}/5 <img src={require('./files/star.png')} alt="star" className='star-img'/></span>
              </div>
              <div className='review-tile-desc'>{reviews[reviewCounter].desc}</div>
            </div>
          <button onClick={handleNextReview} className='arrow-btn-right'><img src={require("./files/arrowrew.png")} alt="arrow" className="arrow-img-right"/></button>
        </div>
      </div>

      <div className='contact'>
        <div className='contact-title'>
          Kontakt
        </div>
        <div className='contact-wrapper'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1190.9958484375277!2d20.66031255685422!3d50.97753014100487!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47182f7750212469%3A0x2d6a60d8b41b7b49!2sSpacerowa%2011%2C%2026-050%20Zagna%C5%84sk!5e0!3m2!1spl!2spl!4v1684267334338!5m2!1spl!2spl" title="map" width="600" height="450" style={{border: "0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" id="google-map"></iframe>
          <div className='contact-info'>
            <p>Gabinet Psychoterapii Uzależnień Bożena Stępień</p>
            <p>Zagnańsk 26-050</p>
            <p>ul. Spacerowa 11</p>
            <p>+48 694 295 954</p>
            <p>bozena.stepien@poczta.onet.pl</p>
          </div>
        </div>
        <div className='contact-additional-info'>
          Wizyty proszę umawiać telefonicznie. Gabinet mieści się w Zagnańsku przy ulicy Spacerowej 11. Wejście do budynku jest od ulicy Borek.
        </div>
      </div>

      <div className='footer'>
        <img src={require('./files/logo.jpg')} id='logo-footer' alt='logo'/>

        <div className='footer-line'></div>

        <div className='footer-contact'>
          <div className='footer-contact-title'>
            Kontakt
          </div>
          <div className='footer-contact-content'>
            <p>Gabinet Psychoterapii Uzależnień Bożena Stępień</p>
            <p>Zagnańsk 26-050, ul. Spacerowa 11</p>
            <p>+48 694 295 954</p>
            <p>bozena.stepien@poczta.onet.pl</p>
          </div>
        </div>

        <div className='footer-socials'>
          <a href="https://www.facebook.com/profile.php?id=100091792000071" target='_blank' rel="noreferrer">
            <div className='footer-socials-facebook'>
              <img src={require('./files/facebook.png')} id='facebook-logo' alt='logo'/>
              Odwiedź mój Facebook
            </div>
          </a>
        </div>
      </div>

    </div>
  );
}

export default App;
